'use strict';

var formValidation = require('base/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');

var base = require('base/login/login');
var recaptchaHelpers = require('recaptcha/recaptcha');


module.exports = $.extend(base, {
    login: function () {
        $('form.login').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            var recaptchaPromise = recaptchaHelpers.helpers.recaptchaProvider(form);
            recaptchaPromise.then(() => {
                $('form.login').trigger('login:submit', e);
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: form.serialize(),
                    success: function (data) {
                        form.spinner().stop();
                        if (!data.success) {
                            formValidation(form, data);
                            $('form.login').trigger('login:error', data);
                        } else {
                            $('form.login').trigger('login:success', data);
                            location.href = data.redirectUrl;
                        }
                    },
                    error: function (data) {
                        if (data.responseJSON.redirectUrl) {
                            window.location.href = data.responseJSON.redirectUrl;
                        } else {
                            $('form.login').trigger('login:error', data);
                            form.spinner().stop();
                        }
                    }
                });
                return false;
            });
        });
    },
    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        // instead to replacing the model content, hide and show functionality
                        $('.modal-content.success').removeClass('d-none');
                        $('.modal-content.form').addClass('d-none');
                        $('.modal-content.success .request-password-title').html(data.receivedMsgHeading);
                        $('.modal-content.success .request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        
                        if (!data.mobile) {
                            $('.modal-content.success #submitEmailButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.modal-content.success .send-email-btn').empty()
                                .html('<a href="'
                                    + data.returnUrl
                                    + '" class="btn btn-primary btn-block">'
                                    + data.buttonText + '</a>'
                                );
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },
    clearResetForm: function () {
        $('.modal').on('hidden.bs.modal', function () {
            $('.modal-content.success').addClass('d-none');
            $('.modal-content.form').removeClass('d-none');
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },
    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            var recaptchaPromise = recaptchaHelpers.helpers.recaptchaProvider(form);
            recaptchaPromise.then(() => {
                $('form.registration').trigger('login:register', e);
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: form.serialize(),
                    success: function (data) {
                        form.spinner().stop();
                        if (!data.success) {
                            $('form.registration').trigger('login:register:error', data);
                            formValidation(form, data);
                        } else {
                            $('form.registration').trigger('login:register:success', data);
                            location.href = data.redirectUrl;
                        }
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                        }

                        form.spinner().stop();
                    }
                });
                return false;
            });
        });
    }
});
